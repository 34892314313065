import axios from "axios";
import Jwt from "./Jwt";
import ErrorTransformer from "./ErrorTransformer";
import notHasModeViewAs from "./NotHasModeViewAs";

class Interceptor {
  constructor(timeout, dispatch, reduxState) {
    this.baseURL = process.env.REACT_APP_API_LOGIN;
    this.dispatch = dispatch;
    this.reduxState = reduxState;
    this.client = axios.create({
      baseURL: this.baseURL,
      timeout: timeout || 9000
    });
  }

  getInstance(additionalHeaders = null) {
    return new Promise((resolve, reject) => {
      try {
        const state = this.reduxState;
        const token = state.auth.user.token;
        const refreshToken = state.auth.user.refreshToken;
        const isDebugMode = false;
        if (token) {
          const decodedToken = Jwt.decode(token);
          if (decodedToken.exp < new Date() / 1000 || 0) {
            this.client.get(`${this.baseURL}/refreshtoken/${refreshToken}`).then(response => {
              this.dispatch({ type: "AUTH_USER", token: response.data.token, refreshToken: response.data.refreshtoken });
              this.setClientRequest(true, response.data.token, additionalHeaders, isDebugMode);
              resolve(this.client);
            })
              .catch(e => {
                if (e.response.status === 403) {
                  reject({ message: "Sessão expirada", description: isDebugMode && e.response.message ? e.response.message : "Refresh Token expirado!" });
                } else {
                  reject(e.response.data);
                }
              });
          } else {
            this.setClientRequest(true, token, additionalHeaders, isDebugMode).then(() => {
              resolve(this.client);
            })
              .catch(err => {
                reject(err);
              });
          }
        } else {
          reject({ message: "Não foi possível autenticar esse usuário. Tente novamente.", description: "Token não informado!" });
        }
      } catch (e) {
        reject({ message: "Não foi possível concluir sua solicitação!", details: e.response && e.response.message ? e.response.message : "Falha na requisição" });
      }
    });
  }

  setClientRequest(hasToken, token, additionalHeaders = null, isDebugMode) {
    return new Promise((resolve, reject) => {
      let hasError = false;
      this.client.interceptors.response.use(response => {
        response.aux = {
          error: {
            // command: `curl -H "Content-Type:application/json" -H "Authorization: ${response.config.headers.Authorization}" "${response.config.baseURL}${response.config.url}"`
          }
        };

        return response;
      }, error => Promise.reject(error));
      this.client.interceptors.request.use(config => {
        config.headers = { "Content-Type": "application/json" };
        if (hasToken) config.headers.Authorization = `Bearer ${token}`;
        if (additionalHeaders) config.headers = { ...config.headers, ...additionalHeaders };

        // Bloqueia toda ação de post, put e delete (Bloqueio Adicional)
        if (
          !notHasModeViewAs(false, this.reduxState.auth.user.viewAs)
          && (config.method === "post" || config.method === "put" || config.method === "delete")
        ) {
          return false;
        }

        return config;
      }, error => {
        hasError = true;
        const _error = {};
        _error.code = 0;
        _error.message = "O conteúdo que você está acessando está indisponível no momento. Tente novamente ou volte mais tarde.";
        _error.details = isDebugMode && error.response.message ? error.response.message : "Cabeçalho não pode ser definido na requisição";
        // _error.command = `curl -H "Content-Type:application/json" -H "Authorization: ${error.config.headers.Authorization}" "${error.config.baseURL}${error.config.url}"`;

        return Promise.reject(_error);
      });
      this.client.interceptors.response.use(response => response, error => {
        hasError = true;
        let _error = {};
        _error = ErrorTransformer.parse(error, isDebugMode);
        // _error.command = `curl -H "Content-Type:application/json" -H "Authorization: ${error.config.headers.Authorization}" "${error.config.baseURL}${error.config.url}"`;

        return Promise.reject(_error);
      });
      if (hasError) {
        reject();
      }
      resolve();
    });
  }
}
export default Interceptor;
