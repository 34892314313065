import Interceptor from "../../../helpers/Interceptor";
import * as TourAction from "../../global/tour/TourAction";

export const _TYPES = {
  _getPreferences: "PREFERENCES::GET_PREFERENCES",
  _setPreferences: "PREFERENCES::SET_PREFERENCES"
};
export const _STATUS = {
  _fetching: "FETCHING",
  _done: "DONE",
  _failed: "FAILED"
};
const settings = {};
settings.getPreferences = () => (dispatch, getState) => {
  dispatch({
    type: _TYPES._getPreferences,
    status: _STATUS._fetching
  });
  new Interceptor(undefined, dispatch, getState()).getInstance()
    .then(client => {
      client({
        baseURL: process.env.REACT_APP_API_USER_PREFERENCES,
        method: "get",
        url: `/preferences/${getState().auth.user.ra}`
      })
        .then(response => {
          const preferences = response.data.status === 204 || !response.data.payload.preferences ? {} : response.data.payload.preferences.reduce(((r, c) => Object.assign(r, c)), {});
          dispatch({
            type: _TYPES._getPreferences,
            status: _STATUS._done,
            preferences
          });
          dispatch(TourAction.default.forceShowPageGuidedTour(preferences.ava30GuidedTour, preferences.ava30GuidedTour));
        })
        .catch(error => {
          dispatch({
            type: _TYPES._getPreferences,
            status: _STATUS._failed,
            error
          });
        });
    })
    .catch(error => {
      dispatch({ type: _TYPES._getPreferences, status: _STATUS._failed, error });
    });
};
settings.setPreferences = preference => (dispatch, getState) => {
  dispatch({
    type: _TYPES._setPreferences,
    status: _STATUS._fetching
  });
  const new_preferences = { ...getState().auth.preferences.preferences, ...preference };
  const preferences = Object.keys(new_preferences).map(key => {
    const obj = {};
    obj[key] = new_preferences[key];

    return obj;
  });
  new Interceptor(undefined, dispatch, getState()).getInstance()
    .then(client => {
      client({
        baseURL: process.env.REACT_APP_API_USER_PREFERENCES,
        method: "post",
        url: "/preferences",
        data: {
          username: getState().auth.user.ra,
          preferences
        }
      })
        .then(() => {
          dispatch({
            type: _TYPES._setPreferences,
            status: _STATUS._done,
            preferences
          });
        })
        .catch(error => {
          dispatch({
            type: _TYPES._setPreferences,
            status: _STATUS._failed,
            error
          });
        });
    })
    .catch(error => {
      dispatch({ type: _TYPES._setPreferences, status: _STATUS._failed, error });
    });
};
export default settings;
