import * as queryString from "query-string";
import store2 from "store2";
import registerApplications from "./services/RegisterApplications";
import authenticate from "./services/Authentication";
import "./App.scss";
import setDebugMode from "./services/UrlParamsSettings";

const loginRedirect = "https://login.kroton.com.br";
const queryValues = queryString.parse(window.location.search);
const userData = store2.namespace("userData");
const tokenQueryString = queryValues.token;
const tokenLocalStorage = userData("token");

const getUserToken = () => {
  if (tokenQueryString) userData("token", tokenQueryString);

  return userData("token");
};

const initializeChatbot = () => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src = process.env.REACT_APP_CHAT_SCRIPT;
  document.getElementsByTagName("body")[0].appendChild(script);
  const c = document.createElement("div");
  c.setAttribute("id", "kchatbot");
  const kc = document.createElement("webcomponent-chatbot");
  c.appendChild(kc);
  document.getElementsByTagName("body")[0].appendChild(c);
};

const initialChangeDOM = () => {
  initializeChatbot();
  document.getElementById("loader").remove();
  document.getElementById("layout-base").style.display = "flex";
};

const initialize = async () => {
  try {
    await authenticate(getUserToken());
    await setDebugMode(queryValues.debugMode);
    registerApplications();
    initialChangeDOM();
  } catch (err) {
    window.location = loginRedirect;
  }
};

if (tokenQueryString === undefined && tokenLocalStorage === null) window.location = loginRedirect;
window.addEventListener("load", initialize);
