import * as PreferencesAction from "./PreferencesAction";

const initialState = {
  preferences: {},
  status: "FETCHING",
  type: undefined
};
const settings = (state = initialState, action) => {
  switch (action.type) {
    case "LOGOUT":
      return action.status === "DONE" ? initialState : state;
    case "AUTHENTICATION::SET_TOKEN":
      return initialState;
    case PreferencesAction._TYPES._getPreferences:
      return {
        ...state,
        type: action.type,
        ...PreferencesAction._STATUS._done !== action.status ? {} : action.preferences ? { preferences: action.preferences } : {},
        status: action.status,
        error: action.error
      };
    case PreferencesAction._TYPES._setPreferences:
      return {
        ...state,
        type: action.type,
        ...PreferencesAction._STATUS._done !== action.status ? {} : action.preferences ? { preferences: action.preferences } : {},
        status: action.status,
        error: action.error
      };
    default:
      return state;
  }
};
export default settings;
