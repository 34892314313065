import store2 from "store2";
import axios from "axios";
import { reducers, actions } from "../redux";
import { _TYPES } from "../redux/auth/user/UserAction";

const listenRedux = () => {
  reducers.store.subscribe(() => {
    const loginRedirect = "https://login.kroton.com.br";
    const user = reducers.store.getState().auth.user;

    if (user.type === "LOGOUT" && user.status === "DONE") {
      store2.clearAll();
      window.location = loginRedirect;
    }
    if (user.type === _TYPES._changeAva && user.status === "DONE") {
      window.location = user.redirect;
    }
    if (user.type === _TYPES._getUserInfo && user.status === "FAILED") {
      window.location = loginRedirect;
    }
  });
};

const getHomePage = role => {
  switch (role) {
    case "student": return "/cockpit";
    case "tutor": return "/forum/list";
    default: return "/";
  }
};

const saveUserData = (token, response) => {
  const data = {
    ...response.data.data[0],
    role: response.data.customdata.role,
    homePage: getHomePage(response.data.customdata.role),
    token
  };
  if (["student", "tutor"].indexOf(data.role) < 0) {
    reducers.store.dispatch({
      type: _TYPES._getUserInfo,
      status: "FAILED",
      error: "Você não tem acesso à essa página"
    });
  } else {
    reducers.store.dispatch({
      type: _TYPES._getUserInfo,
      status: "DONE",
      brand: response.data.customdata.brand,
      enrolledDisciplines: response.data.customdata.shortnames
        ? Object.values(response.data.customdata.shortnames)
        : [],
      payload: data
    });
  }
};

const authenticate = async token => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_LOGIN,
    timeout: 9000,
    headers: { Authorization: `Bearer ${token}` }
  });
  try {
    listenRedux();
    const response = await instance.get(`/login?t=${new Date().getTime()}`);
    saveUserData(token, response);
    actions.auth.preferences.getPreferences()(reducers.store.dispatch, reducers.store.getState);
  } catch (err) {
    throw new Error("Não foi possivel autenticar o usuário");
  }
};

export default authenticate;
