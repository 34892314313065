import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import forumReducer from "./forum/ForumReducer";
import forumAction from "./forum/ForumAction";
import messagingAction from "./messaging/MessagingAction";
import messagingReducer from "./messaging/MessagingReducer";

let notificationReducer = combineReducers({
  forum: forumReducer,
  messaging: messagingReducer
});
const notificationAction = {
  forum: forumAction,
  messaging: messagingAction
};
notificationReducer = persistReducer({
  key: "notification",
  storage,
  whitelist: [],
  blacklist: []
}, notificationReducer);
export { notificationReducer, notificationAction };
