import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import userReducer from "./user/UserReducer";
import userAction from "./user/UserAction";
import preferencesReducer from "./preferences/PreferencesReducer";
import preferencesAction from "./preferences/PreferencesAction";

let authReducer = combineReducers({
  user: userReducer,
  preferences: preferencesReducer
});
const authAction = {
  user: userAction,
  preferences: preferencesAction
};
authReducer = persistReducer({
  key: "auth",
  storage,
  whitelist: ["user"],
  blacklist: []
}, authReducer);
export { authReducer, authAction };
