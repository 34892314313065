import * as Properties from "./PropertiesAction";

const initialState = {
  showDrawerTree: false,
  showDrawerProfile: false,
  showDropdownMenu: false,
  showPointsDetails: false,
  showToast: false,
  msgToast: "",
  showFloatButton: false,
  floatButtonLeftIcon: undefined,
  floatButtonRightIcon: undefined,
  onCopyRequest: undefined,
  onPrintScreen: undefined,
  floatButtonTitle: undefined,
  floatButtonOnClick: () => {},
  showSideMenu: true,
  showDrawerSideMenu: false,
  forceShowTree: true,
  viewAsClosed: false,
  showDrawerCalendar: false,
  type: undefined
};
const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGOUT":
      return action.status === "DONE"
        ? {
          ...initialState,
          type: action.type,
          error: action.error,
          status: action.status
        }
        : {
          ...state,
          type: action.type,
          error: action.error,
          status: action.status
        };
    case Properties._TYPES._setShowDrawerTree:
      return {
        ...state,
        type: action.type,
        showDrawerTree: action.show
      };
    case Properties._TYPES._setShowDrawerProfile:
      return {
        ...state,
        type: action.type,
        showDrawerProfile: action.show
      };
    case Properties._TYPES._showSideMenu:
      return {
        ...state,
        type: action.type,
        showSideMenu: action.show
      };
    case Properties._TYPES._showDrawerSideMenu:
      return {
        ...state,
        type: action.type,
        showDrawerSideMenu: action.show
      };
    case Properties._TYPES._setShowPointsDetails:
      return {
        ...state,
        type: action.type,
        showPointsDetails: action.show
      };
    case Properties._TYPES._setShowDropdownMenu:
      return {
        ...state,
        type: action.type,
        showDropdownMenu: action.show
      };
    case Properties._TYPES._forceShowTree:
      return {
        ...state,
        type: action.type,
        forceShowTree: action.force
      };
    case Properties._TYPES._setShowToast:
      return {
        ...state,
        type: action.type,
        showToast: action.details.show,
        msgToast: action.details.msg,
        state: action.details.state,
        icon: action.details.icon,
        onCopyRequest: action.details.show ? action.details.onCopyRequest : undefined,
        onPrintScreen: action.details.show ? action.details.onPrintScreen : undefined
      };
    case Properties._TYPES._setShowFloatButton:
      return {
        ...state,
        type: action.type,
        showFloatButton: action.visible,
        floatButtonLeftIcon: action.leftIcon,
        floatButtonRightIcon: action.rightIcon,
        floatButtonTitle: action.title,
        floatButtonOnClick: action.onClick
      };
    case Properties._TYPES._setViewAsClosed:
      return {
        ...state,
        type: action.type,
        viewAsClosed: action.closed
      };
    case Properties._TYPES._setShowDrawerCalendar:
      return {
        ...state,
        type: action.type,
        showDrawerCalendar: action.show
      };
    default:
      return state;
  }
};
export default propertiesReducer;
