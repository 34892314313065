import { type, validate } from "../helpers/InterfaceValidators";

class EnrolledCourse {
  constructor(properties = {}) {
    this.fullname = validate(type("string"), properties.fullname);
    this.ra = validate(type("string"), properties.ra);
    this.active = validate(type("boolean", false), properties.active);
  }
}
export default EnrolledCourse;
