import * as SettingsAction from "./SettingsAction";

const initialState = {
  type: null,
  debugMode: false
};
const settings = (state = initialState, action) => {
  switch (action.type) {
    case "LOGOUT":
      return action.status === "DONE" ? initialState : state;
    case "AUTHENTICATION::SET_TOKEN":
      return initialState;
    case SettingsAction._TYPES._setDebugMode:
      return {
        ...state,
        type: action.type,
        debugMode: action.active
      };
    default:
      return state;
  }
};
export default settings;
