class ErrorTransformer {
  static parse(error, messageFromResponse = false) {
    const _error = {};
    if (error === undefined || error.response === undefined || error.response.data === undefined) {
      _error.code = 0;
      _error.message = "O conteúdo que você está acessando está indisponível no momento. Tente novamente ou volte mais tarde.";
      _error.details = "Erro na requisição: Indefinido";
    } else {
      _error.message = messageFromResponse && error.response.data.message ? error.response.data.message : "O conteúdo que você está acessando está indisponível no momento. Tente novamente ou volte mais tarde.";
      _error.code = error.response.status;
      switch (error.response.status) {
        case 400:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Solicitação Inválida";
          break;
        case 401:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Não autorizado";
          break;
        case 402:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Pagamento necessário";
          break;
        case 403:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Proibido";
          break;
        case 404:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Não encontrado";
          break;
        case 405:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Método não permitido";
          break;
        case 406:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Não aceito";
          break;
        case 407:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Autenticação de Proxy Necessária";
          break;
        case 408:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Tempo de solicitação esgotado";
          break;
        case 409:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Conflito";
          break;
        case 410:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Perdido";
          break;
        case 411:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Duração necessária";
          break;
        case 412:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Falha de pré-condição";
          break;
        case 413:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Solicitação da entidade muito extensa";
          break;
        case 414:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Solicitação de URL muito Longa";
          break;
        case 415:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Tipo de mídia não suportado";
          break;
        case 416:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Solicitação de faixa não satisfatória";
          break;
        case 417:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Falha na expectativa";
          break;
        case 500:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Erro do Servidor Interno";
          break;
        case 501:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Não implementado";
          break;
        case 502:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Porta de entrada ruim";
          break;
        case 503:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Serviço Indisponível";
          break;
        case 504:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Tempo limite da Porta de Entrada";
          break;
        case 505:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Versão HTTP não suportada";
          break;
        default:
          _error.details = messageFromResponse && error.response.data.trace ? error.response.data.trace : "Erro na requisição: Indefinido";
          break;
      }
    }

    return _error;
  }
}
export default ErrorTransformer;
