import { reducers } from "../redux";
import { _TYPES } from "../redux/global/settings/SettingsAction";

const setDebugMode = async debugMode => {
  try {
    reducers.store.dispatch({
      type: _TYPES._setDebugMode,
      active: debugMode === "active_trace"
    });
  } catch (err) {
    throw new Error("Não foi possivel configurar a aplicação com os parametros de URL informados");
  }
};

export default setDebugMode;
