import * as PreferencesAction from "../../auth/preferences/PreferencesAction";

export const _TYPES = {
  _forceShowPageGuidedTour: "TOUR::FORCE_SHOW_PAGE_GUIDED_TOUR",
  _showPageGuidedTour: "TOUR::SHOW_PAGE_GUIDED_TOUR",
  _changeActiveGuidedTourStep: "TOUR::CHANGE_ACTIVE_GUIDED_TOUR_STEP",
  _setNotShowGuidedTourModal: "TOUR::SET_NOT_SHOW_GUIDED_TOUR_MODAL",
  _setGuidedTourStatus: "TOUR::SET_GUIDED_TOUR_STATUS",
  _setAutomaticTour: "TOUR::SET_AUTOMATIC_TOUR",
  _getShowTourInfo: "TOUR::GET_SHOW_TOUR_INFO"
};
export const _STATUS = {
  _fetching: "FETCHING",
  _done: "DONE",
  _failed: "FAILED"
};

export const _TOUR_STATUS = {
  _defined: "DEFINED",
  _ready: "READY",
  _concluded: "CONCLUDED"
};

const tourAction = {};
tourAction.forceShowPageGuidedTour = (forceShow = true, openModal = true) => dispatch => {
  dispatch({
    type: _TYPES._forceShowPageGuidedTour, status: "DONE", forceShow, openModal
  });
};
tourAction.showPageGuidedTour = (show = true, isPage = true, name) => dispatch => {
  dispatch({
    type: _TYPES._showPageGuidedTour, status: "DONE", show, isPage, name
  });
};
tourAction.setGuidedTourStatus = (name, tourStatus = _TOUR_STATUS._defined) => dispatch => {
  setTimeout(() => {
    dispatch({
      type: _TYPES._setGuidedTourStatus, status: "DONE", tourStatus, name
    });
  }, 1000);
};
tourAction.changeActiveGuidedTourStep = (step, name) => dispatch => {
  dispatch({
    type: _TYPES._changeActiveGuidedTourStep, status: "DONE", step, name
  });
};
tourAction.setNotShowGuidedTourModal = (savePreferences = false, ignoreHelpHighlight = false) => dispatch => {
  dispatch({ type: _TYPES._setNotShowGuidedTourModal, status: "DONE", forceShow: false });
  if (ignoreHelpHighlight) {
    dispatch({
      type: _TYPES._showPageGuidedTour, status: "DONE", show: true, isPage: true
    });
  } else {
    dispatch({
      type: _TYPES._showPageGuidedTour, status: "DONE", show: true, isPage: false, name: "TOUR_HELP_INDICATOR"
    });
  }
  if (savePreferences) {
    dispatch(PreferencesAction.default.setPreferences({ ava30GuidedTour: false }));
  }
};
tourAction.setAutomaticTour = () => dispatch => {
  dispatch({ type: _TYPES._setAutomaticTour, status: "DONE" });
};
export default tourAction;
