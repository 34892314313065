import Interceptor from "../../../helpers/Interceptor";

export const _TYPES = {
  _getNotification: "FORUM::GET_NOTIFICATION"
};
export const _STATUS = {
  _fetching: "FETCHING",
  _done: "DONE",
  _failed: "FAILED"
};
const action = {};
action.getNotification = () => (dispatch, getState) => {
  dispatch({
    type: _TYPES._getNotification,
    status: _STATUS._fetching,
    payload: {}
  });
  new Interceptor(undefined, dispatch, getState()).getInstance()
    .then(client => {
      client({
        baseURL: process.env.REACT_APP_API_NOTIFICATION_FORUM,
        method: "get",
        url: `/topic/notification/${getState().auth.user.ra}`
      })
        .then(response => {
          dispatch({
            type: _TYPES._getNotification,
            status: _STATUS._done,
            payload: response.data.notifications
          });
        })
        .catch(error => {
          dispatch({
            type: _TYPES._getNotification,
            status: _STATUS._failed,
            error
          });
        });
    });
};
export default action;
