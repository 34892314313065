import { combineReducers, createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import { authReducer as auth } from "./auth";
import { globalReducer as global } from "./global";
import { notificationReducer as notification } from "./notification";

const asyncReducers = {};

const createReducer = (asyncReducers = {}) => combineReducers({
  auth,
  global,
  notification,
  ...asyncReducers
});

const store = createStore(createReducer(),
  composeWithDevTools(applyMiddleware(ReduxThunk)));

store.injectAsyncReducer = (name, asyncReducer) => {
  asyncReducers[name] = asyncReducer;
  store.replaceReducer(createReducer(asyncReducers));
};

export default {
  store,
  persist: persistStore(store)
};
