import * as Authentication from "./UserAction";
import * as Properties from "../../global/properties/PropertiesAction";
import UserInterface from "../../../interfaces/User";
import EnrolledDisciplineInterface from "../../../interfaces/EnrolledDiscipline";
import EnrolledCourseInterface from "../../../interfaces/EnrolledCourse";

const initialState = {
  ...new UserInterface({
  }),
  token: null,
  refreshToken: null,
  error: undefined,
  redirect: undefined,
  status: "FETCHING",
  type: null
};
const user = (state = initialState, action) => {
  switch (action.type) {
    case "LOGOUT":
      return action.status === "DONE" ? {
        ...initialState,
        type: action.type,
        error: action.error,
        redirect: action.redirect,
        role: action.role,
        status: action.status
      } : {
        ...state,
        type: action.type,
        error: action.error,
        redirect: action.redirect,
        role: action.role,
        status: action.status
      };
    case "persist/REHYDRATE":
      if (action.key === "auth" && action.payload && action.payload.user && state.token === null) {
        return {
          ...state,
          ...action.payload.user,
          type: "persist/REHYDRATE",
          token: action.payload.user.token !== null ? action.payload.user.token : state.token,
          refreshToken: action.payload.user.refreshToken !== null ? action.payload.user.refreshToken : state.refreshToken,
          status: "DONE"
        };
      }

      return state;
    case Authentication._TYPES._getUserInfo:
      return {
        ...state,
        status: action.status,
        redirect: action.redirect,
        type: action.type,
        ...Authentication._STATUS._done !== action.status ? {} : new UserInterface({
          firstName: action.payload.firstname,
          lastName: action.payload.lastname,
          fullName: action.payload.fullname,
          role: action.payload.role,
          homePage: action.payload.homePage,
          viewAs: action.payload.viewAs,
          token: action.payload.token,
          profileImageSmall: `https://${process.env.REACT_APP_USER_IMAGE_BUCKET}.s3.amazonaws.com/profile/${action.payload.username}/picture/${action.payload.username}.jpeg`,
          profileImage: `https://${process.env.REACT_APP_USER_IMAGE_BUCKET}.s3.amazonaws.com/profile/${action.payload.username}/picture/${action.payload.username}.jpeg`,
          brand: action.brand,
          theme: "default",
          // theme: action.brand,
          email: action.payload.email,
          semester: action.payload.semester,
          id: action.payload.id,
          ra: action.payload.username,
          enrolledDisciplines: action.enrolledDisciplines ? action.enrolledDisciplines.map(ed => new EnrolledDisciplineInterface({
            cdCurso: ed.cd_curso,
            cdDisciplina: ed.cd_disciplina,
            cdInstituicao: ed.cd_instituicao,
            cdPessoa: ed.cd_pessoa,
            cdTurma: ed.cd_turma,
            fullname: ed.fullname,
            role: ed.role,
            shortname: ed.shortname,
            destino: ed.destino,
            shortnameGroup: ed.shortname_grupo,
            objetivo: ed.objetivo,
            urlImage: ed.url_image,
            origem: ed.origem,
            idnumber: ed.idnumber,
            showDiscipline: ed.showDiscipline,
            sequence: ed.sequencia,
            sigla: ed.sigla
          })) : [],
          enrolledCourses: [
            new EnrolledCourseInterface({
              fullname: action.payload.department,
              ra: action.payload.username,
              active: true
            })
          ]
        }),
        error: action.error
      };
    case Authentication._TYPES._changeAva:
      return {
        ...state,
        type: action.type,
        error: action.error,
        status: action.status,
        redirect: action.redirect
      };
    case Properties._TYPES._setNotShowGuidedTourModal:
      return {
        ...state,
        type: action.type,
        error: action.error,
        status: action.status,
        tour: false
      };
    default:
      return state;
  }
};
export default user;
