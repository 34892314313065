import * as singleSpa from "single-spa";
import { reducers } from "../redux";

const register = () => {
  const showWhenPrefix = routes => location => routes.some(route => location.pathname.startsWith(route));

  singleSpa.registerApplication("header",
    () => import("../apps/Header"),
    () => true);

  singleSpa.registerApplication("overlay",
    () => import("../apps/Overlay"),
    () => true);

  singleSpa.registerApplication("cockpit",
    () => import("ava30-cockpit-microfront"),
    showWhenPrefix(["/cockpit"]),
    { Reducer: reducers, env: process.env, baseUrl: "/cockpit" });

  singleSpa.registerApplication("messaging",
    () => import("portal-do-docente-microfront-messaging"),
    showWhenPrefix(["/mail"]),
    { Reducer: reducers, env: process.env, baseUrl: "/mail" });

  singleSpa.registerApplication("calendar",
    () => import("ava30-calendar-microfront"),
    showWhenPrefix(["/calendar"]),
    { Reducer: reducers, env: process.env, baseUrl: "/" });

  singleSpa.registerApplication("forum",
    () => import("ava30-forum-microfront"),
    showWhenPrefix(["/forum"]),
    { Reducer: reducers, env: process.env, baseUrl: "/" });

  singleSpa.registerApplication("pti",
    () => import("ava30-pti-adm-microfront"),
    showWhenPrefix(["/pti"]),
    { Reducer: reducers, env: process.env, baseUrl: "/" });

  singleSpa.registerApplication("discipline",
    () => import("ava30-digital-classroom-microfront"),
    showWhenPrefix(["/discipline"]),
    { Reducer: reducers, env: process.env, baseUrl: "/" });

  singleSpa.start();
};

export default register;
