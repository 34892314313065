import * as ActionsForumNotification from "./ForumAction";

const initialState = {
  type: null,
  status: "FETCHING",
  error: undefined,
  hasNotification: false
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGOUT":
      return action.status === "DONE"
        ? {
          ...initialState,
          type: action.type,
          error: action.error,
          status: action.status
        }
        : {
          ...state,
          type: action.type,
          error: action.error,
          status: action.status
        };
    case "AUTHENTICATION::SET_TOKEN":
      return {
        ...initialState
      };
    case ActionsForumNotification._TYPES._getNotification:
      try {
        return {
          ...state,
          type: action.type,
          status: action.status,
          hasNotification: ActionsForumNotification._STATUS._done !== action.status ? state.hasNotification : action.payload,
          error: action.error
        };
      } catch (e) {
        return {
          ...state,
          type: action.type,
          status: ActionsForumNotification._STATUS._failed,
          error: e
        };
      }
    default:
      return state;
  }
};
export default reducer;
